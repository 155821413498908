<template>
    <Header/>
    <!-- <Preloader/> -->
    <router-view/>
    <Footer/>
</template>

<script>
    import {Header,Footer} from '@/components'
    export default {
        name: 'MainLayout',
        components: {
            Header,Footer
        }
    }
</script>